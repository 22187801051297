import React from 'react'
import './footer.scss'
import { Link } from 'gatsby'
import links from "../data/links-ml.json"

export default function FooterMl() {
    return (
        <div className="footer">
            <div className="blocks container">
                <div className="block width33">
                    <h2>Liens rapides</h2>
                    {links.data.map((data) => (
                        <Link key={data.id} to={data.url} className="link_animate">
                            <span className="texte_lien_span">
                                {data.text}
                            </span>
                        </Link>
                    ))}
                </div>
                <div className="block width33">
                    <h2>Contact</h2>
                    <a href="https://goo.gl/maps/xiJVuojwNb3RYUQ48" target="_blank" rel="noreferrer">
                        <span>
                            681 route du Reban<br />
                            73270 Villard-sur-Doron
                        </span>
                    </a>
                    <a href="tel:+33676293739" className="tel link_animate">
                        <span>
                            06 76 29 37 39
                        </span>
                    </a>
                    <a href="mailto:contact@verslesplans.fr" className="email link_animate">
                        <span>
                            contact@verslesplans.fr
                        </span>
                    </a>
                </div>
            </div>

            <div className="copyright">
                <p>2022 © Vers les Plans - <Link to="/mentions-legales" className="link_animate"><span>Mentions Légales</span></Link></p>
            </div>
        </div>
    )
}