import React from 'react'
import { Link } from 'gatsby'
import './404.scss'
import { StaticImage } from 'gatsby-plugin-image'
import LayoutMl from '../components/layout-ml'

export default function error() {

    return (
        <div className="page_404">
            <LayoutMl>

                <div className="relative bloc_404">
                    <StaticImage src="../images/chalet/chambre_chalet_standing_motagne_les_saisies_alpes_savoie.jpg" alt="Error Page" />
                    <h1>Page non trouvée !</h1>
                    <Link to="/" className="button_animate">
                        <span>
                            Retour à l'accueil
                        </span>
                    </Link>
                </div>

            </LayoutMl>
        </div>
    )
}