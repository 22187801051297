import React from "react"
import FooterMl from "./footer-ml"
import NavMl from "./nav-ml"

const LayoutMl = ({ children }) => {

  return (
    <div className="global-wrapper">
        <NavMl />
        <main>{children}</main>
        <FooterMl />
    </div>
  )
}

export default LayoutMl